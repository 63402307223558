import React from 'react';
import { AuthProvider } from "react-oidc-context";

const getRedirectUri = () => {
  if (typeof window !== 'undefined') {
    return `${window.location.origin}/scheduling/schedule/`;
  }
  return 'https://wheelshockeydevelopment.com/scheduling/schedule/';
};
const cognitoAuthConfig = {
  // authority: "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_SHhdC8svH",
  // client_id: "2dumtbaefdkenhu4ii4vcjiuv6",
  
  authority: `${process.env.GATSBY_CONGNITO_AUTH_AUTHORITY}`,
  client_id: `${process.env.GATSBY_CONGNITO_AUTH_CLIENT_ID}`,
  // redirect_uri: "http://localhost:8000/scheduling/schedule/",
  redirect_uri: getRedirectUri(),
  response_type: "code",
  scope: "email openid phone",
};


export function RootWrapper({ element }) {
  return (
    <AuthProvider {...cognitoAuthConfig}>
      {element}
    </AuthProvider>
  );
} 